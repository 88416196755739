@import "src/variables";

.timeline {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 70px;
  @media (max-width: 767px) {
    display: none;
  }
  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-top: 1px solid #4a5373;
    padding-top: 24px;
    @media (max-width: 767px) {
      width: 50%;
      border: none;
      padding-bottom: 16px;
      padding-top: 0;
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
      &:nth-child(odd) {
        margin-left: 1px;
        float: right;
        padding-left: 20px;
        border-left: 1px solid #374459;
        left: -1px;
        .timeline__item_date {
          &:before {
            background: linear-gradient(270deg, #00cd86 2.17%, rgba(0, 205, 134, 0) 81.52%);
          }
        }
        &:after {
          left: -5px;
          right: auto;
        }
      }
      &:nth-child(even) {
        margin-right: 1px;
        float: left;
        align-items: flex-end;
        padding-right: 20px;
        border-right: 1px solid #374459;
        .timeline__item_date {
          &:before {
            right: -18px;
            left: auto;
            background: linear-gradient(90deg, #00cd86 2.17%, rgba(0, 205, 134, 0) 81.52%);
          }
          &:after {
            right: -25px;
            left: auto;
          }
        }
      }
      &:last-child {
        border-color: transparent !important;
        &:before {
          content: "";
          display: block;
          height: 100%;
          width: 1px;
          background: linear-gradient(180deg, #374459, rgba(0, 0, 0, 0));
          position: absolute;
          right: -1px;
          top: 0;
        }
        &:after {
          display: none;
        }
      }
      &_last {
        &:after {
          display: block;
          content: "";
          position: absolute;
          width: 7px;
          height: 7px;
          border: 1px solid $green;
          border-radius: 7px;
          background: $green;
          bottom: -7px;
          right: -5px;
          z-index: 1;
        }
      }
    }
    &:first-child {
      &::before {
        content: "";
        width: calc((100vw - 1228px) / 2);
        position: absolute;
        left: calc((100vw - 1228px) / -2);
        top: -1px;
        height: 1px;
        background: linear-gradient(270deg, #00cd86 40.83%, rgba(0, 205, 134, 0) 105.99%);
        @media (max-width: 1280px) {
          width: 24px;
          left: -24px;
        }
      }
    }
    &:last-child {
      &::after {
        content: "";
        width: calc((100vw - 1228px) / 2);
        position: absolute;
        right: calc((100vw - 1228px) / -2);
        top: -1px;
        height: 1px;
        background: linear-gradient(#4a5373, #4a5373 40%, rgba(0, 0, 0, 0));
        @media (max-width: 1280px) {
          width: 24px;
          right: -24px;
        }
      }
    }
    &_date {
      font-size: 14px;
      line-height: 20px;
      color: $l6;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        color: $green;
        top: -8px;
        margin-bottom: -4px;
      }
      &:before {
        content: "";
        width: 1px;
        height: 18px;
        position: absolute;
        left: 5px;
        top: -25px;
        background: linear-gradient(0deg, #4a5473 2.17%, rgba(74, 83, 114, 0) 81.52%);
        @media (max-width: 768px) {
          width: 10px;
          height: 1px;
          background: linear-gradient(270deg, #4a5473 2.17%, rgba(74, 83, 114, 0) 81.52%);
          left: -18px;
          top: 10px;
        }
      }
      &:after {
        content: "";
        width: 11px;
        height: 11px;
        border-radius: 6px;
        border: 1px solid #4a5373;
        position: absolute;
        top: -31px;
        left: -1px;
        background: #101218;
        @media (max-width: 768px) {
          width: 7px;
          height: 7px;
          border: 1px solid #4a5373;
          left: -25px;
          top: 6px;
        }
      }
    }
    &_title {
      font-family: Conthrax;
      font-weight: 600;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.04em;
      color: $l6;
      text-transform: uppercase;
      padding: 14px 16px;
      border: 1px solid #374459;
      border-radius: 4px;
      &:last-child {
        margin-top: 10px;
      }
      @media (max-width: 768px) {
        padding: 10px 8px;
      }
    }
    &_active {
      border-color: $green;
      @media (max-width: 768px) {
        border-color: $green !important;
        .timeline__item_title {
          display: flex;
          align-items: center;
          &:after {
            display: block;
            content: "";
            min-width: 14px;
            min-height: 14px;
            background: url("./../../../assets/images/check.svg") no-repeat;
            background-size: contain;
            margin-left: 8px;
          }
        }
      }
      .timeline__item_date {
        color: $green;
        &:before {
          background: linear-gradient(0deg, #00cd86 2.17%, rgba(0, 205, 134, 0) 81.52%);
        }
        &:after {
          content: "";
          width: 15px;
          height: 15px;
          border-radius: 8px;
          border: none;
          top: -32px;
          left: -2px;
          background: url("./../../../assets/images/check.svg") #101218 no-repeat;
          background-size: cover;
          @media (max-width: 768px) {
            width: 7px;
            height: 7px;
            border: 1px solid $green;
            background: $green;
            left: -25px;
            top: 6px;
          }
        }
      }
      .timeline__item_title {
        color: $green;
      }
    }
    &_last {
      border-color: #4a5373;
    }
  }
}
.timeline1 {
  &__item {
    align-items: center;
    &:first-child {
      &::before {
        width: calc((100vw - 950px) / 2);
        @media (max-width: 1200px) {
          width: calc((100vw - 905px) / 2);
        }
        @media (max-width: 1024px) {
          width: calc((100vw - 640px) / 2);
        }
      }
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
  &__button {
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    left: 0px;
    top: -18px;
    z-index: 1;
    background: rgba(50, 59, 87, 0.23);
    border: 1px solid rgba(62, 72, 104, 0.25);
    backdrop-filter: blur(8px);
    img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 16px;
      object-fit: contain;
    }
    // @media (max-width: 1500px) {
    //   left: 0;
    // }
  }
  :global(.btn_next) {
    left: unset;
    right: 0;
    img {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}
