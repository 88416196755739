@import "src/variables";

.table_block {
  margin: 0 auto 163px;
  width: 1022px;
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    @media (max-width: 767px) {
      &:last-of-type {
        flex-direction: column;
        align-items: stretch;
      }
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #51ad5f;
      border-radius: 4px;
      min-width: 286px;
      height: 54px;
      font-size: 14px;
      text-transform: uppercase;
      color: $l4;
      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
  :global(.table) {
    height: 375px;
    overflow: auto;
  }
}

.table {
  width: 100%;
  border: 1px solid #2b3246;
  border-radius: 10px;
  margin-top: 16px;
  margin-bottom: 24px;

  tr {
    &:not(:last-child) {
      td {
        border-bottom: 1px solid #2b3246;
      }
    }
    @media (max-width: 1025px) {
      &:nth-child(1) {
        display: none;
      }
    }
    th {
      padding: 16px 12px 8px 10px;
      text-align: left;
      border-bottom: 1px solid #2b3246;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $grey;
      width: 4%;
    }
    td {
      padding: 12px;
      font-size: 14px;
      line-height: 16px;
      color: $l6;
      width: 3%;
      @media (max-width: 1025px) {
        padding-top: 36px;
        padding-right: 0;
        padding-bottom: 8px;
        padding-left: 16px;
        font-size: 12px;
        line-height: 16px;
        &:nth-child(3) {
          display: none;
        }
        &:nth-child(4) {
          padding-right: 16px;
        }
        &::before {
          position: absolute;
          top: 16px;
          left: 16px;
          content: attr(data-title);
          font-size: 10px;
          line-height: 16px;
          color: #59648a;
          font-weight: bold;
        }
      }
      div {
        display: flex;
        align-items: center;
        gap: 10px;
        @media (max-width: 767px) {
          img {
            height: 20px;
          }
          a {
            img {
              height: 15px;
            }
          }
        }
      }
    }
  }
  &__address {
    @media (max-width: 767px) {
      width: 53px;
      display: block !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
      display: block;
    }
  }
  &__assets {
    margin-left: 23px;
    @media (max-width: 546px) {
      margin-left: 0px;
    }
    &__pair_icons {
      gap: 0px !important;
      flex-direction: column;
      img {
        height: 26px;
        border: 2px solid #021326;
        border-radius: 24px;
        @media (max-width: 767px) {
          width: 24px;
          height: 24px !important;
          border: 1px solid #021326;
        }
      }
      div:nth-child(1) {
        margin-left: -23px;
      }
      div:nth-child(2) {
        margin-top: -15px;
      }
    }
    &__pair_info {
      flex-direction: column;
      gap: 0px !important;
      align-items: flex-start !important;
      h3 {
        font-size: 11px;
        line-height: 14px;
        color: $green;
      }
      p {
        font-family: "Cabin" !important ;
        font-size: 13px !important;
        color: #fff !important;
        line-height: 16px !important;
      }
      @media (max-width: 546px) {
        h3 {
          font-size: 9px;
          line-height: 11px;
        }
        p {
          font-size: 11px !important;
          line-height: 12px;
        }
      }
    }
  }
  &__sentiments {
    flex-direction: column;
    gap: 8px !important;
    align-items: flex-start !important;
    &__info {
      gap: 6px !important;
      span {
        height: 5px;
        width: 5px;
        border-radius: 5px;
        background-color: $green;
      }
      p {
        font-size: 8px !important;
        line-height: 9.6px !important;
        color: $green;
        font-family: "Conthrax" !important;
      }
    }
    &__bar {
      position: relative;
      width: 74px;
      height: 5.21px;
      border-radius: 21.5px;
      background-color: #bf3d3d;
      span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        transition: width 0.15s ease-in;
        border-radius: 21.5px;
        background-color: $green;
      }
    }
  }
  &__type,
  &__five,
  &__fifteen,
  &__hourly,
  &__daily {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 0px !important;
    strong {
      font-size: 12px !important;
      text-transform: capitalize;
      width: max-content;
      @media (max-width: 767px) {
        font-size: 10px !important;
        font-family: "Cabin" !important;
        font-weight: 400;
      }
    }
  }

  &__graph {
    width: 160px;
    height: 41px;
    @media (max-width: 767px) {
      width: 59px;
      height: 17px;
    }
    &__feature {
      @media (max-width: 767px) {
        width: calc(100% - 20px);
        height: 30px;
      }
    }
  }
  &__assetsRow {
    width: 12%;
  }
}

.featureTable_TradeNow {
  display: block !important;
}

.featureTable_Row_Mobile {
  td {
    @media (max-width: 1025px) {
      &:nth-child(4) {
        padding-right: 0px !important;
      }
      &:nth-child(5) {
        padding-right: 16px;
      }
    }
  }
}

.featureTable {
  tr {
    @media (max-width: 1025px) {
      &:not(:last-child) {
        td {
          border-bottom: none;
        }
      }
      &:nth-child(2n + 1) {
        td {
          border-bottom: 1px solid #2b3246;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
    // td:first-child {
    //   width: 12%;
    // }
    th:first-child {
      width: 12%;
    }
  }
  &__sm_row,
  &__sm_col {
    width: 5% !important;
  }
  &__md_col,
  &__md_row {
    // width: 12% !important;
  }
}

.activityTable {
  tr {
    th {
      font-family: "Conthrax";
      font-size: 12px;
      line-height: 16px;
      padding: 16px 16px 8px;

      @media (max-width: 546px) {
        font-size: 10px;
        div:last-child {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 53px;
        }

        &:last-child {
          max-width: 53px;
        }
      }
    }
    td {
      padding: 24px 16px;
      strong {
        font-family: "Cabin";
        font-size: 14px;
        line-height: 16px;
        @media (max-width: 546px) {
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    @media (max-width: 1025px) {
      &:nth-child(1) {
        display: table-row;
      }
    }
    td {
      @media (max-width: 1025px) {
        padding: 24px 16px;
        &:nth-child(3) {
          display: table-cell;
        }
        &:nth-child(4) {
          padding-right: 16px;
        }
        &::before {
          position: absolute;
          top: 16px;
          left: 16px;
          content: attr(data-title);
          font-size: 10px;
          line-height: 16px;
          color: #59648a;
          font-weight: bold;
          display: none;
        }
      }
    }
  }
}

:global(.show-more) {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 12px;
  p {
    color: $green;
    font-size: 14px;
    line-height: 16.8px;
    text-transform: uppercase;
    font-family: "Conthrax";
    cursor: pointer;
  }
  img {
    height: 8px;
    fill: $green;
    object-fit: contain;
  }
}
