@import "src/variables";

.feature_in {
  padding: 50px 0;

  h1 {
    text-align: center;
    color: $green;
    margin-bottom: 48px;
    font-size: 34px;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 30px;
      width: calc(100% + 32px);
      left: -16px;
    }
  }

  .featured_partner {
    display: flex;
    gap: 60px;
    justify-content: center;
    flex-wrap: wrap;

    img {
      width: 200px;
      cursor: pointer;

      @media screen and (max-width: 850px) {
        width: 150px;
      }

      @media screen and (max-width: 500px) {
        width: 130px;
      }
    }
  }
}
