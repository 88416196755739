@import "src/variables";

.animation {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  transition: opacity 0.2s ease-out;
}
.page_loader_wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #101218;
  z-index: 11;

  img {
    height: 100%;
    width: 100%;
    max-height: 150px;
    @media (max-width: 767px) {
      max-height: 100px;
    }
    object-fit: contain;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    transition: opacity 0.2s ease-out;
  }
}
.loader {
  height: 30px;
  width: 40px;
  position: relative;
  background-color: transparent !important;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: 0.3s;
  animation-fill-mode: both;
  transition: opacity 0.2s ease-out;

  &__circle {
    position: absolute;
    width: 15px;
    height: 15px;
    @media (max-width: 767px) {
      width: 10px;
      height: 10px;
    }
    border-radius: 50%;
    animation: loader 1s linear infinite;
  }
  span:nth-child(1) {
    left: 0px;
  }
  span:nth-child(2) {
    left: 20px;
    @media (max-width: 767px) {
      left: 15px;
    }
    top: 0px;
    animation-delay: 0.1s;
  }
  span:nth-child(3) {
    top: 0px;
    left: 40px;
    @media (max-width: 767px) {
      left: 30px;
    }
    animation-delay: 0.2s;
  }
}

@keyframes loader {
  0% {
    background: $green;
    opacity: 0.8;
  }
  50% {
    background: $green;
    opacity: 0.2;
  }
  100% {
    background: $green;
    opacity: 0.4;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
