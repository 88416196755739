@import "src/variables";

.markets {
  padding-top: 163px;
  padding-bottom: 210px;
  background: url("./../../assets/images/markets__bg.svg") no-repeat top center;
  background-size: cover;
  @media (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 60px;
    background: url("./../../assets/images/markets__bg_mobile.svg") no-repeat top center;
    background-size: 100%;
  }
  h2 {
    text-align: center;
    margin-bottom: 32px;
    color: $green;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      letter-spacing: 0.04em;
      margin-bottom: 24px;
    }
  }
  &__signals {
    &_row {
      display: flex;
      justify-content: space-between;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: stretch;
        gap: 16px;
      }
      h3 {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.04em;
        color: #ffffff;
        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
      p {
        width: 655px;
        @media (max-width: 767px) {
          width: 100%;
        }
      }
      &:last-child {
        p {
          width: 741px;
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        width: 291px;
        height: 54px;
        border: 1px solid #374459;
        border-radius: 4px;
        @media (max-width: 767px) {
          width: 100%;
        }
        span {
          font-size: 14px;
          letter-spacing: 0.04em;
          color: $l3;
          text-transform: uppercase;
        }
      }
    }
    picture {
      > img {
        width: 100%;
        margin: 32px 0;
      }
    }
  }
  :global(.table) {
    height: 372px;
    overflow: auto;
  }
}
