@import "src/variables";

.market {
  :global(.container) {
    display: flex;
    flex-direction: row;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1024px) {
    margin-bottom: 48px;
  }
  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__stats {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    @media (min-width: 768px) {
      margin-bottom: 70px;
      border: 1px solid rgba(205, 214, 245, 0.3);
      border-bottom: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: auto auto;
      row-gap: 24px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      @media (min-width: 768px) {
        border-right: 1px solid rgba(205, 214, 245, 0.3);
        width: 300px;
        padding-top: 28px;
        text-align: center;
        &:last-child {
          border-right: none;
        }
      }

      > strong {
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.04em;
        color: $l6;
        margin-bottom: 8px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 11px;
          line-height: 13px;
        }
      }
      div {
        display: flex;
        align-items: center;
        @media (min-width: 768px) {
          justify-content: center;
        }
        strong {
          font-size: 18px;
          line-height: 22px;
          letter-spacing: 0.04em;
          color: $green;
          margin-right: 8px;
          @media (max-width: 768px) {
            font-size: 15px;
            line-height: 18px;
          }
        }
        span {
          font-size: 14px;
          line-height: 20px;
          color: $grey;
        }
      }
    }
  }
  &__chart {
    width: calc(100% - 295px);
    height: 480px;
    > div {
      width: calc(100% + 32px) !important;
      left: -16px;
    }
    :global(.recharts-yAxis .recharts-cartesian-axis-ticks) {
      & > g:last-child text {
        display: none;
      }
    }
    // :global(.recharts-xAxis .recharts-cartesian-axis-ticks) {
    //   & > g:last-child text {
    //     // display: none;
    //   }
    // }
    :global(.recharts-yAxis .recharts-cartesian-axis-ticks text) {
      font-size: 14px;
      font-family: "Conthrax";
      transform: translateX(-16px);
    }
    :global(.recharts-xAxis .recharts-cartesian-axis-ticks text) {
      font-size: 14px;
      font-family: "Conthrax";
      transform: translateY(16px);
    }
    :global(.recharts-xAxis .recharts-label) {
      transform: translateY(14px);
      transform: translateX(12px);
    }
    :global(.recharts-yAxis .recharts-label) {
      transform: translateX(-10px);
    }
    :global(.recharts-xAxis .recharts-label tspan),
    :global(.recharts-yAxis .recharts-label tspan) {
      font-size: 15px;
      fill: $grey;
    }
    @media (max-width: 768px) {
      height: 215px;
      width: 100%;
      > div {
        width: calc(100% + 64px) !important;
        left: -48px;
      }
      :global(.recharts-yAxis .recharts-cartesian-axis-ticks text) {
        font-size: 8px;
        transform: translateX(-8px);
      }
      :global(.recharts-xAxis .recharts-cartesian-axis-ticks text) {
        font-size: 8px;
        transform: translateY(8px);
      }
      :global(.recharts-xAxis .recharts-label) {
        transform: translateY(4px) translateX(14px);
      }
      :global(.recharts-yAxis .recharts-label) {
        transform: translateY(-9px) translateX(7px);
      }
      :global(.recharts-xAxis .recharts-label tspan),
      :global(.recharts-yAxis .recharts-label tspan) {
        font-size: 8px;
        fill: $grey;
      }
    }
  }
}
.activity {
  margin-top: 60px;
  h3 {
    text-transform: uppercase;
    font-size: 22px;
    line-height: 26px;
    color: $l6;
    margin-bottom: 24px;
  }
}
.tooltip {
  font-size: 16px;
  line-height: 22px;
  color: #c2cbea;
}

.buy {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
  &__block {
    &:not(:last-child) {
      margin-bottom: 21px;
    }
    > h3 {
      font-size: 22px;
      line-height: 26.4px;
      color: $l6;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      color: $l6;
      margin-bottom: 12px;
    }
  }
  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    width: 100%;
    background: #51ad5f;
    border-radius: 6px;
    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #175610;
      margin-bottom: 4px;
    }
    div {
      display: flex;
      align-items: center;
      img {
        &:not(:last-child) {
          margin-right: 9px;
        }
      }
    }
  }
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    h3 {
      font-size: 14px;
      line-height: 17px;
      color: $l6;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    span {
      font-size: 16px;
      line-height: 19px;
      color: $green;
    }
  }
  &__perform {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }
  :global(.message) {
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      text-shadow: 0px 0px 14px rgb(255 255 255 / 45%);
    }
  }
  :global(.tooltip) {
    position: relative !important;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    div:nth-child(2) {
      display: flex;
    }
  }
}
