@import "src/variables";

.stepper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 287px;

  &__step {
    &__step_header {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #101218;
      border: 1px solid $l1;
      border-radius: 4px;
      gap: 6px;
      padding: 6px 12px;
      position: relative;
      h3 {
        font-size: 10px;
        line-height: 12px;
        color: $l6;
        opacity: 0.5;
        text-transform: uppercase;
        transition: all 0.15s ease-in;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        color: $l3;
        opacity: 0.8;
        margin-bottom: 0;
        position: absolute;
        top: 25px;
        left: 45px;
      }
      div:nth-child(1) {
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border-radius: 50%;
        span {
          font-size: 11px;
          line-height: 12px;
          font-family: "Conthrax";
          color: #0d1422;
          background: #6e7487;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: 2px solid #0d1422;
        }
      }
    }
    &__step_body {
      transition: all 0.15s ease-out;
      height: 0px;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      p {
        font-size: 14px;
        line-height: 20px;
        color: $l6;
        font-family: "Cabin";
        opacity: 0.8;
      }
      div {
        p {
          font-size: 16px;
          margin-bottom: 10px;
        }
        :global(.action-btns) {
          display: flex;
          gap: 10px;
          button {
            color: $green;
            text-transform: uppercase;
            font-family: "Conthrax";
            background: $l2;
            border-radius: 4px;
            border: 1px solid $l1;
            padding: 11px 43px;
            backdrop-filter: blur(8px);
          }
        }
      }
      &__two {
        button {
          width: 100%;
          font-size: 10px;
          height: 40px;
          &:hover {
            background-color: $l3;
          }
        }

        input {
          background-color: transparent;
          outline: none;
          border-radius: 4px;
          border: 1px solid $l1;
          width: 100%;
          padding: 10px 12px;
          font-size: 10px;
          margin-bottom: 12px;
          transition: border 0.15s ease-in;
          transform-origin: center;
          color: #fff;
          font-family: "Cabin";
          letter-spacing: 0.04em;
          &:focus {
            border: 1px solid #fff;
          }
        }
      }
      :global(.stage_three) {
        background-color: transparent !important;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        p {
          font-size: 14px;
          text-align: center;
          line-height: 20px;
        }
        :global(.confirmation_text) {
          font-size: 16px;
          line-height: 19px;
          color: $l6;
        }
        :global(.pdf_file) {
          display: flex;
          gap: 10px;
          align-items: center;
          background-color: transparent !important;
          margin-bottom: 24px;
          a {
            font-size: 15px;
            line-height: 20px;
            color: $l3;
            opacity: 0.8;
            text-decoration: none;
          }
        }
        button {
          width: 100%;
          height: 40px;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }

    &__kyc {
      p {
        margin-bottom: 44px !important;
        text-align: left !important;
      }

      button {
        font-size: 10px;
        line-height: 12px;
        width: 100%;
        padding: 11px 35px 11px 50px;
        height: fit-content;
      }
      :global(.success_msg) {
        font-size: 16px !important;
        line-height: 19px !important;
        margin-bottom: 24px !important;
      }
    }
  }
  :global(.active) {
    div:nth-child(1) {
      background-color: #181c27;
      h3 {
        opacity: 1;
      }
      div {
        background-color: $green;
        span {
          background-color: $green;
        }
      }
    }
    div:nth-of-type(2) {
      overflow: unset;
      position: relative;
      padding: 16px 0px 24px 40px;
      min-height: 390px;
      visibility: visible;
      opacity: 1;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 26px;
        height: calc(100% + 8px);
        width: 1px;
        background-color: $l1;
      }
    }
    :global(.step_three) {
      padding-left: 0 !important;
      &::before {
        display: none;
      }
      button {
        width: 100%;
        font-size: 10px;
        line-height: 12px;
        height: 44px;
      }
    }
  }

  :global(.completed) {
    div:nth-child(1) {
      background-color: #101218;
      h3 {
        opacity: 0.5;
      }
      div {
        span {
          background-color: $green;
          outline: none;
        }
      }
    }
    div:nth-of-type(2) {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 26px;
        height: calc(100% + 8px);
        width: 1px;
        background-color: $l1;
      }
    }
  }
  @media (max-width: 546px) {
    width: 100%;
    // :global(.active) {
    //   div:nth-of-type(2) {
    //     height: 314px;
    //   }
    // }
  }
}

.buy {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 100%;
  }
  &__block {
    &:not(:last-child) {
      margin-bottom: 21px;
    }
    > h3 {
      font-size: 22px;
      line-height: 26.4px;
      color: $l6;
      margin-bottom: 16px;
      text-transform: uppercase;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      color: $l6;
      margin-bottom: 12px;
    }
  }
  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    width: 100%;
    background: #51ad5f;
    border-radius: 6px;
    span {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #175610;
      margin-bottom: 4px;
    }
    div {
      display: flex;
      align-items: center;
      img {
        &:not(:last-child) {
          margin-right: 9px;
        }
      }
    }
  }
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    background-color: transparent !important;
    h3 {
      font-size: 14px;
      line-height: 17px;
      color: $l6;
      letter-spacing: 0.04em;
      text-transform: uppercase;
    }
    span {
      font-size: 16px;
      line-height: 19px;
      color: $green;
    }
  }
  &__perform {
    font-size: 12px;
    line-height: 14px;
    width: 100%;
  }
  :global(.message) {
    font-size: 14px;
    text-align: center;
    line-height: 20px;
  }
}
:global(.disable) {
  background-color: #51ad5f8a;
  cursor: default;
  &:hover {
    background-color: #51ad5f8a;
  }
}
:global(.loader) {
  width: 26px;
  height: 26px;
  border: 2px solid #ffffffa8;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
