@import "src/variables";

.loader {
  height: 30px;
  width: 40px;
  position: relative;
  background-color: transparent !important;
  &__circle {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    animation: loader 1s linear infinite;
  }
  span:nth-child(1) {
    left: 0px;
  }
  span:nth-child(2) {
    left: 15px;
    top: 0px;
    animation-delay: 0.1s;
  }
  span:nth-child(3) {
    top: 0px;
    left: 30px;
    animation-delay: 0.2s;
  }
}

@keyframes loader {
  0% {
    background: $green;
    opacity: 0.8;
  }
  50% {
    background: $green;

    opacity: 0.2;
  }
  100% {
    background: $green;
    opacity: 0.4;
  }
}
