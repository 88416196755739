@import 'src/variables';

.tabs {
  display: flex;
  gap: 8px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 4px;
    padding: 0 24px;
    gap: 8px;
    height: 30px;
    font-size: 10px;
    line-height: 12px;
    color: $grey;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    &_active {
      border: 1px solid #374459;
      color: $green;
    }
    img {
      top: -1px;
    }
  }
  &_adaptive {
    .tabs__item {
      padding: 0 12px;
    }
    &__items {
      position: absolute;
      top: calc(100% + 8px);
      z-index: 1;
      background: #101218;
      padding: 4px;
      border-radius: 4px;
      width: auto;
      white-space: nowrap;
    }
  }
  &_active {
    button {
      img {
        transform: rotate(180deg);
      }
    }
  }
}