@import "src/variables";

.token_sale {
  padding-top: 113px;
  padding-bottom: 0;
  background: url("../../assets/images/presale__bg.png") no-repeat;
  background-size: 100%;
  background-position: 0px -55px;
  @media (max-width: 1600px) {
    background-position: -10px 60px;
  }
  @media (max-width: 1024px) {
    position: static;
    padding-top: 140px;
    padding-bottom: 57px;
    background: url("../../assets/images/presale__bg_mobile.png") no-repeat;
    background-position: 0px -40px;
    background-size: 100%;
    :global(.container) {
      position: static;
    }
  }
  @media (max-width: 767px) {
    background-position: 0px 70px;
  }
  h2 {
    text-align: center;
    font-size: 34px;
    line-height: 46px;
    color: $green;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  &__presale {
    display: flex;
    flex-direction: row;
    margin-bottom: 85px;
    align-items: center;
    gap: 29px;
    &__info {
      max-width: 600px;
      h2 {
        font-size: 40px;
        line-height: 54px;
      }
      h2,
      p {
        color: $l6;
        text-align: left;
      }
    }
    :global(.raised) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      div {
        border: 1.4px solid $l1;
        border-radius: 8px;
        padding: 12px 11px;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-end;
        h3 {
          color: $green;
          font-size: 32px;
        }
        span {
          font-size: 15px;
          font-family: "Conthrax";
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        h3 {
          font-size: 14px;
          margin-bottom: 6px;
        }
        div {
          justify-content: center;
          width: 100%;
          gap: 6px;
          padding: 8px 11px;
          h3 {
            @media (max-width: 767px) {
              font-size: 24px;

              margin-bottom: 0;
            }
          }
          span {
            font-size: 15px;
            font-family: "Conthrax";
          }
        }
      }
    }
    &__timer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 420px;
      width: 602px;
      padding: 40px;
      position: relative;
      background: url("./../../assets/images/timer__border.svg") no-repeat;
      background-size: 100%;
      &__btns,
      :global(.startTimer),
      :global(.start_time),
      :global(.end_time) {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      div {
        h3 {
          font-size: 18px;
          line-height: 27.96px;
          color: $l6;
          text-transform: capitalize;
        }
        span {
          color: $grey;
        }
      }
      &__btns {
        margin-bottom: 35px;
        button {
          text-transform: uppercase;
          font-size: 12px;
          line-height: 14.4px;
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 4px;
          padding: 15px 0px;
          width: 247px;
          position: relative;

          &:first-child {
            color: #51ad5f;
            border: 2px solid #51ad5f;
            padding-left: 32px;
          }
          &:last-child {
            justify-content: center;
            font-size: 14px;
            color: #ffffff;
            padding-left: 16px;
            background-color: #479fdb;
            border-color: #479fdb;
            img {
              position: absolute;
              left: 16px;
            }
          }
        }
      }
      :global(.startTimer) {
        gap: 10px;
        margin-bottom: 36px;
        h3 {
          min-width: 107px;
          margin-bottom: 0;
          margin-top: 12px;
        }
      }
      :global(.start_time) {
        margin-bottom: 24px;
      }
      :global(.start_time),
      :global(.end_time) {
        align-items: center;
        h3 {
          margin-top: 0;
          margin-bottom: 0;
        }
        div {
          padding: 12px 16px;
          border-radius: 8px;
          border: 1px solid $l1;
          max-width: 370px;
          justify-content: center;
          p {
            color: $green;
            font-size: 20px;
            line-height: 30px;
          }
        }
      }
    }
    &__started {
      height: 323px;
      background: url("../../assets/images/rectangle.svg") no-repeat;
      justify-content: center;
      :global(.startTimer) {
        margin-bottom: 53px;
      }

      :global(.startTimer) {
        div {
          strong {
            color: #f65555;
          }
        }
      }
    }
    &__ended {
      height: 550px;
      background: url("./../../assets/images/timer__border__presale.svg") no-repeat;
      background-size: 100%;
      h3 {
        color: $l6;
        font-size: 20px;
        margin-bottom: 24px;
      }
      :global(.raised) {
        margin-bottom: 24px;
        h3 {
          margin-bottom: 0;
        }
        div {
          h3 {
            margin-bottom: 0;
          }
        }
      }
    }
    @media (max-width: 1280px) {
      &__info {
        h2 {
          font-size: 30px;
        }
      }
      &__timer {
        background-size: contain;
        &__btns {
          gap: 15px;
        }
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      &__timer {
        margin-top: 20px;
      }
    }
    @media (max-width: 767px) {
      &__info {
        h2 {
          text-align: center;
        }
        button {
          width: 100%;
        }
      }
      &__timer {
        height: 515px;
        background: url("./../../assets/images/timer__border_mobile.svg") no-repeat;
        background-size: contain;
        padding: 32px 25px;
        width: 345px;
        margin-left: auto;
        margin-right: auto;

        &__btns,
        :global(.startTimer),
        :global(.start_time),
        :global(.end_time) {
          flex-direction: column;
          div {
            width: 100%;
            p {
              text-align: center;
            }
          }
        }
        &__btns {
          margin-bottom: 15px;
          button {
            width: 295px;
            justify-content: center;
            &:last-child {
              gap: 16px;
            }
          }
        }
        :global(.startTimer) {
          h3 {
            margin-top: 0px;
          }
          margin-bottom: 26px;
        }
        :global(.start_time) {
          margin-bottom: 20px;
        }
        :global(.start_time),
        :global(.end_time) {
          h3 {
            margin-bottom: 6px;
          }
          align-items: flex-start;
          div {
            p {
              font-size: 16px;
            }
          }
        }
      }
      &__started {
        height: 274px;
        background: url("../../assets/images/timer__border_mobile_1.svg") no-repeat;

        :global(.startTimer) {
          h3 {
            font-size: 14px;
          }
        }
      }
      &__ended {
        height: 651px;
        background: url("../../assets/images/timer__border__presale_mobile.svg") no-repeat;
        h3 {
          font-size: 14px;
          margin-bottom: 15px;
        }
        :global(.startTimer),
        :global(.start_time),
        :global(.end_time) {
          h3 {
            font-size: 14px;
          }
        }
        :global(.raised) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.reward {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: transparent;
  border: none;
  border-radius: 6px;
  margin-bottom: 66px;
  span {
    color: $green;
    text-decoration: underline;
    cursor: pointer;
  }

  p {
    font-size: 19px;
    line-height: 30px;
  }
  @media (max-width: 768px) {
    margin-bottom: 50px;
    p {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }
  img {
    min-width: 27px;
    width: 27px;
    margin-right: 12px;
  }
}
