@import 'src/variables';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 286px;
  height: 54px;
  border-radius: 4px;
  background: $l3;
  font-family: Conthrax;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $l4;
  transition: 0.3s;
  &:hover {
    background: darken($l3, 15%);
  }
}