@import "src/variables";

.header {
  position: fixed;
  top: -1px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0 24px;
  border-bottom: 1px solid #374459;
  z-index: 4;

  &__logged_in_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #090e1a;
    border: 1px solid #374459;
    border-radius: 4px;
    padding: 14px 0;
    width: 180px;
    gap: 7px;
    cursor: pointer;
    @media (max-width: 546px) {
      width: 140px;
    }
    h3 {
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      color: #fff;
    }
  }
  &_scrolled {
    background: linear-gradient(rgba(0, 0, 0, 0.75) 85%, rgba(0, 0, 0, 0) 100%);
  }
  &_active {
    background: #101218;
  }
  &__menu {
    display: flex;
    gap: 16px;
    li svg {
      width: 20px;
      position: absolute;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);

      path {
        transition: 0.3s;
        fill: white;
      }
    }

    a {
      color: $l6;
      font-size: 15px;
      line-height: 22px;
      transition: 0.3s;
      &:hover {
        color: #00cd86;
        svg path {
          fill: #00cd86;
        }
      }
    }
  }
  &__group {
    display: flex;
    height: 38px;
    transition: 0.3s;
    &_hidden {
      opacity: 0;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    background: #f08f1c;
    border-radius: 4px;
    color: #78250b;
    font-size: 10px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    transition: 0.3s;
    &:hover {
      background: darken(#f08f1c, 15%);
    }
  }
  &__burger {
    display: none;
    height: 17px;
    width: 20px;
    span {
      position: absolute;
      width: 100%;
      height: 3px;
      background: #ffffff;
      transition: 0.3s;
      left: 0;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 7px;
      }
      &:nth-child(3) {
        top: 14px;
      }
    }
    &_active {
      span {
        &:nth-child(1) {
          top: 6px;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          top: 6px;
          transform: rotate(-45deg);
        }
      }
    }
  }
  @media (max-width: 1280px) {
    &__logo {
      left: 72px;
    }
    &__menu {
      display: none;
      &_active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(100% - 1px);
        height: calc(100vh - 100% + 2px);
        width: 100%;
        left: 0;
        padding: 24px;
        background: #101218;
        z-index: 2;
        gap: 18px;
      }
    }
    &__burger {
      display: block;
    }
  }
  @media (max-width: 767px) {
    height: 60px;
    padding: 0 8px 0 16px;
    &__logo {
      left: 55px;
      position: absolute;
    }
  }
}
