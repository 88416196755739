@import "src/variables";

.integration_partners {
  padding: 80px 0 0px 0;
  margin-top: 30px;

  h1 {
    text-align: center;
    color: $green;
    margin-bottom: 25px;
    font-size: 34px;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 18px;
      width: calc(100% + 32px);
      left: -16px;
    }
  }

  .integration {
    max-width: 900px;
    margin: 0 auto;

    p {
      font-size: 20px;
      line-height: 24px;
      line-break: auto;
      font-weight: 400;
      text-align: center;

      @media (max-width: 769px) {
        font-size: 19px;
      }

      @media (max-width: 600px) {
        font-size: 17px;
      }

      @media (max-width: 450px) {
        font-size: 15px;
      }
    }

    .partnerContainer {
      padding-top: 40px;
      display: flex;
      justify-content: center;
      gap: 70px;
      flex-wrap: wrap;

      img {
        height: 40px;

        &:last-child {
          height: 40px;
        }
      }

      @media (max-width: 465px) {
        // height: 40px;
        display: grid;
        grid-template-columns: 1fr;
        justify-items: center;

        img {
          height: 40px;

          &:last-child {
            height: 30px;
          }
        }
      }
    }
  }
}
