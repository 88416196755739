@import "src/variables";

.popup_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #020610;
    opacity: 0.8;
  }
}

.popup {
  max-width: 675px;
  width: 675px;
  background: #181d2d;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  max-height: 98vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #5555555e;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 20px 17px;
    border-bottom: 1px solid #374459;
    strong {
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 24px 48px;

    strong {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 4px;
      color: $green;
      text-transform: uppercase;
      text-align: center;
    }
    p {
      font-size: 11px;
      line-height: 14px;
      color: #ffffff;
      font-weight: 400;

      a {
        color: $green;
      }
    }
    &__info_block {
      display: flex;
      align-items: center;
      padding: 16px 14px;
      gap: 8px;
      width: 100%;
      background-color: #222a3f;
      border-radius: 6px;
      margin-bottom: 16px;
      p {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        span {
          color: $green;
        }
      }
    }
    &__link_block {
      margin-top: 32px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #fff;
      }
      &__wrapper {
        display: flex;
        width: 100%;
        gap: 16px;
        div {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 12px;
          border: 1px solid $l1;
          border-radius: 4px;
          backdrop-filter: blur(8px);
          padding: 11px 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: pre;
          p {
            font-size: 13px;
            line-height: 16px;
            font-weight: 700;
            text-transform: uppercase;
            position: relative;
            padding-left: 10px;
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              height: 100%;
              width: 1px;
              background-color: $l1;
            }
          }
          span {
            color: #fff;
            width: 90%;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
        button {
          font-size: 13px;
          line-height: 16px;
          font-family: "Conthrax";
          text-transform: uppercase;
          color: $l1;
          padding: 14px 24px 13px;
          border-radius: 6px;
          white-space: nowrap;
          background: linear-gradient(0deg, #222a3f, #222a3f), #ffffff;
        }
        &__copy_btn {
          background: $l3 !important;
          color: $l4 !important;
          transition: 0.3s;

          &:hover {
            background: #397942 !important;
          }
        }
      }
    }
    &__login_details {
      margin-top: 32px;
      margin-bottom: 39px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      width: 100%;
      div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        position: relative;
        p {
          font-weight: 700;
          font-family: "Cabin";
          font-size: 14px;
          line-height: 17px;
          color: #b6c2ea;
        }
        h3 {
          font-size: 12px;
          line-height: 14px;
          font-weight: 600;
          cursor: pointer;
          text-transform: uppercase;
          color: #fff;
          &:hover {
            text-shadow: 0px 0px 14px rgba(255, 255, 255, 0.45);
          }
        }
        :global(.user) {
          cursor: text;
          &:hover {
            text-shadow: none;
          }
        }
      }
    }
    @media (max-width: 767px) {
      &__link_block {
        &__wrapper {
          flex-direction: column;
        }
      }
    }
    &__claim_btn {
      width: 142px;
      height: 43px;
    }
    button {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0 16px;
    &__body {
      &__link_block {
        &__wrapper {
          flex-direction: column;
        }
      }
      &__login_details {
        div {
          flex-direction: column;
          gap: 10px;
          width: fit-content;
        }
      }
    }
  }
}
.token_sale {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 19px;
  padding-bottom: 24px;
  position: relative;
  margin-bottom: 32px;
  overflow: auto;
  &::before,
  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0.85px;
    background-color: $l1;
    left: 0;
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  h2,
  h3 {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0.04em;
  }
  h3 {
    font-size: 13px;
    line-height: 16px;
    color: #4ea65a;
    text-decoration: underline;
    cursor: pointer;
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__body {
    margin-top: 20px;
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    &__stage {
      border: 1px solid $l1;
      background: #181c27;
      border-radius: 8px;
      padding: 14px 16px 24px;
      display: flex;
      flex-direction: column;
      width: 100%;
      h3 {
        color: $green;
        font-size: 12px;
        line-height: 14px;
        text-decoration: none;
      }
      p {
        color: #fff;
        font-size: 12px;
        font-family: "Cabin";
        line-height: 16.8px;
      }
      &__name {
        margin-bottom: 41px;
        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 0.5px;
          background-color: $l1;
          left: 0;
          bottom: -16px;
        }
        h3 {
          margin-bottom: 6px;
        }
      }
      &__info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        div {
          display: flex;
          flex-direction: column;
          gap: 8px;
          p {
            text-transform: capitalize;
            line-height: 14px;
            color: $l6;
          }
          h3 {
            span {
              margin-left: 6px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    &__header {
      flex-direction: column;
      gap: 12px;
      align-items: center;
    }
    &__body {
      &__stage {
        &__info {
          flex-direction: column;
          gap: 24px;
        }
      }
    }
  }
}

.tooltip {
  position: absolute !important;
  top: 10px;
  right: 0;
  width: 144px !important;
  flex-direction: column;
  z-index: 1;
  opacity: 0;
  transform: translate(0px, -20px, 0px);
  transition: all 0.15s ease-in-out;
  visibility: hidden;
  &:hover {
    opacity: 1;
    visibility: visible;
  }
  &__show {
    visibility: visible;
    opacity: 1;
    transform: translate(0px, 0px, 0px);
  }
  &__header {
    width: 16px !important;
    height: 16px;
    background-color: $l8;
    border-radius: 4px;
    transform: rotate(45deg) translateX(-50%);
    left: 50%;
    top: 15px;
    z-index: 1;
  }
  &__body {
    border-radius: 6px;
    padding: 10px 12px;
    flex-direction: column;
    gap: 14px;
    background-color: $l8;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    width: 100%;
    p {
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: #fff !important;
    }
    button {
      padding: 7px 32px;
      color: #78250b;
      font-size: 10px;
      line-height: 12px;
      font-family: "Conthrax";
      text-transform: uppercase;
      background-color: #f08f1c;
      border-radius: 4px;
    }
  }
  @media (max-width: 767px) {
    // top: 25px !important;
    left: 0;
    &__header {
      // top: 25px;
    }
  }
}
