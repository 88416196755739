@import "src/variables";

.input {
  margin-bottom: 24px;
  padding: 0 !important;
  min-height: fit-content !important;
  &::before {
    display: none;
  }
  > span {
    display: block;
    font-family: Conthrax;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.04em;
    color: #ffffff;
    margin-bottom: 18px;
  }
  &__block {
    border: 1px solid $l1;
    border-radius: 4px;
    width: 100%;
    padding: 0 !important;
    min-height: fit-content !important;
  }
  &__field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px !important;
    border-bottom: 1px solid $l1;
    min-height: unset !important;
    background-color: transparent !important;
    input {
      font-family: Cabin;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $green;
      width: 100px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
    &_info {
      min-height: fit-content !important;
      display: flex;
      align-items: center;
      background-color: transparent !important;

      button {
        background: #0d1422;
        border: 1px solid $l1;
        border-radius: 4px;
        padding: 8px;
        font-weight: 600;
        font-size: 8px;
        line-height: 10px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $grey;
        margin-right: 8px;
        height: fit-content !important;
      }
      span {
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
        color: #667094;
        background-color: transparent !important;
      }
    }
  }
  &__controls {
    display: flex;
    padding: 0 !important;
    min-height: fit-content !important;
    &::before {
      display: none;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 32px !important;
    }
    &_minus {
      border-right: 1px solid $l1;
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        background: url("./../../../assets/images/minus.svg");
      }
    }
    &_plus {
      &:before {
        content: "";
        width: 16px;
        height: 16px;
        background: url("./../../../assets/images/plus.svg");
      }
    }
  }
}
