@import "src/variables";

.roadmap {
  // margin-top: -450px;
  padding-top: 100px;
  padding-bottom: 86px;
  background: linear-gradient(
    180deg,
    rgba(15, 31, 67, 0) 0%,
    rgba(15, 31, 67, 0.5) 41.15%,
    rgba(16, 23, 39, 0.215) 81.25%,
    rgba(16, 18, 24, 0) 100%
  );
  @media (max-width: 767px) {
    padding-bottom: 0px;
    padding-top: 76px;
    margin-top: 0;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      top: -150px;
      background-size: 100%;
      background: url("../../assets//images/token_sale__bg_mobile.svg");
      height: 100%;
      width: 100%;
      background-position: 0px -699px;
      background-size: 100%;
    }
  }
  h2 {
    font-size: 34px;
    line-height: 46px;
    color: $green;
    text-align: center;
    margin-bottom: 24px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }
  }
  &__info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid rgba(205, 214, 245, 0.3);
    border-bottom: 0;
    border-radius: 8px 8px 0 0;
    margin-bottom: 36px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      margin-bottom: 12px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px 18px 8px;
      text-align: center;
      @media (max-width: 767px) {
        padding: 24px 16px;
      }
      &:nth-child(1) {
        border-right: 1px solid rgba(205, 214, 245, 0.3);
        @media (max-width: 767px) {
          border-right: 0;
          border-bottom: 1px solid rgba(205, 214, 245, 0.3);
        }
      }
      p {
        margin-bottom: 8px;
      }
      a {
        color: $green;
        strong {
          margin-right: 8px;
        }
      }
    }
  }
  &__items {
    &_item {
      float: right;
      display: flex;
      flex-direction: column;
      width: 50.1%;
      padding-left: 60px;
      padding-bottom: 14px;
      border-left: 1px solid $green;
      @media (max-width: 767px) {
        padding-left: 20px;
      }
      &:last-child {
        border-color: transparent !important;
      }
      &:nth-child(even) {
        float: left;
        align-items: flex-end;
        padding-left: 0;
        padding-right: 60px;
        border-left: 0;
        border-right: 1px solid $green;
        right: 2px;
        @media (max-width: 767px) {
          padding-right: 20px;
          right: 0px;
        }
        .roadmap__items_item__date {
          &:before {
            right: -55px;
            left: auto;
            transform: rotate(180deg);
            @media (max-width: 767px) {
              right: -14px;
            }
          }
          &:after {
            right: -66px;
            left: auto;
            @media (max-width: 767px) {
              right: -24px;
            }
          }
        }
        .roadmap__items_item__tasks {
          justify-content: flex-end;
        }
      }
      &__date {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: $green;
        margin-bottom: 4px;
        top: -4px;
        @media (max-width: 767px) {
          line-height: 14px;
        }
        &:before {
          content: "";
          width: 45px;
          height: 1px;
          position: absolute;
          left: -55px;
          top: 9px;
          background: linear-gradient(270deg, #00cd86 2.17%, rgba(0, 205, 134, 0) 81.52%);
          @media (max-width: 767px) {
            width: 10px;
            left: -14px;
            top: 7px;
          }
        }
        &:after {
          content: "";
          width: 11px;
          height: 11px;
          border-radius: 5px;
          background: $green;
          position: absolute;
          top: 4px;
          left: -66px;
          @media (max-width: 767px) {
            width: 7px;
            height: 7px;
            left: -24px;
          }
        }
      }
      &__tasks {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        @media (max-width: 767px) {
          width: 100%;
        }
        div {
          display: flex;
          align-items: center;
          height: 44px;
          border: 1px solid #374459;
          border-radius: 4px;
          font-size: 10px;
          line-height: 12px;
          color: $l6;
          gap: 8px;
          text-transform: uppercase;
          padding: 0 16px;
          @media (max-width: 767px) {
            width: 100%;
            padding: 10px 36px 10px 8px;
            height: auto;
            img {
              position: absolute;
              right: 8px;
              bottom: 8px;
            }
          }
        }
      }
    }
  }
}
