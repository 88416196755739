@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");
@import "src/variables";

@font-face {
  font-family: "Conthrax";
  src: url("./assets/fonts/Conthrax.ttf");
  font-display: swap;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

* {
  position: relative;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  list-style: none;
}

section {
  width: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  @media (min-width: 1920px) {
    &::before {
      content: "";
      width: 300px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #101218, rgba(0, 0, 0, 0));
      z-index: 2;
    }
    &::after {
      content: "";
      width: 300px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(-90deg, #101218, rgba(0, 0, 0, 0));
      z-index: 2;
    }
  }
}

.container {
  width: 1228px;
  @media (max-width: 1280px) {
    width: 100%;
    padding: 0 24px;
  }
  @media (max-width: 767px) {
    padding: 0 16px;
  }
}

body {
  font-family: Cabin, "Segoe UI";
  background: #101218;
}

h1 {
  font-family: Conthrax;
  font-size: 40px;
  line-height: 54px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $l6;
  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 40px;
  }
}

h2 {
  font-family: Conthrax;
  font-size: 34px;
  line-height: 46px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

h3 {
  font-family: Conthrax;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.04em;
}

strong {
  font-family: Conthrax;
}

p {
  font-size: 20px;
  line-height: 30px;
  color: $l6;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

button {
  font-family: Conthrax;
  cursor: pointer;
}

picture {
  pointer-events: none;
  img {
    max-width: 100%;
    max-height: 100%;
    //image-rendering: crisp-edges;
    //image-rendering: -webkit-optimize-contrast;
    //@media (max-width: 767px) {
    //  image-rendering: pixelated;
    //}
  }
}
