@import "src/variables";

.schedule {
  padding-top: 260px;
  padding-bottom: 128px;
  background: url("./../../assets/images/schedule__bg.svg") no-repeat top center;
  background-size: cover;
  margin-bottom: -128px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    background: url("./../../assets/images/schedule__bg_mobile.svg") no-repeat top center;
    background-size: contain;
    padding-top: 125px;
  }
  &__fade {
    content: "";
    width: 100%;
    height: 128px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #101218);
  }
  :global(.container) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__info {
    width: 540px;
    @media (max-width: 768px) {
      width: 100%;
      margin-bottom: 47px;
    }
    h2 {
      color: $l6;
      margin-bottom: 36px;
      @media (max-width: 768px) {
        margin-bottom: 16px;
        font-size: 20px;
        line-height: 24px;
      }
      &:after {
        display: none;
      }
    }
    button img {
      margin-right: 8px;
    }
  }
  picture img {
    width: 436px;
  }
}
