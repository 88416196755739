@import "src/variables";

.trade {
  padding-top: 136px;
  padding-bottom: 64px;
  background: url("./../../assets/images/trade__bg.png") no-repeat top right;

  @media (max-width: 767px) {
    padding-top: 153px;
    padding-bottom: 0;
    background: url("./../../assets/images/trade__bg_mobile.svg") no-repeat top 255px right;
    background-size: 100%;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 128px;

    @media (min-width: 1280px) and (max-width: 1550px) {
      margin-bottom: 20px;
    }
  }

  &__info {
    max-width: 525px;
    flex: 6;
    h1 {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 42px;

      > img {
        top: 4px;
        margin: 0 4px;
      }

      > picture {
        top: 2px;

        img {
          width: 17px;
          margin: 0 8px;
        }
      }
    }

    span {
      color: $l6;
      width: 286px;
      text-align: right;
      display: block;
      margin-top: 5px;

      @media (max-width: 767px) {
        margin: 5px auto;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 286px;
    height: 54px;
    border-radius: 4px;
    background: $l3;
    font-size: 14px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $l4;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 4;

    @media (min-width: 1280px) and (max-width: 1550px) {
    }

    picture {
      @media (min-width: 1280px) and (max-width: 1550px) {
      }

      img {
        width: 100%;
      }
    }

    @media (max-width: 767px) {
      width: 352px;
      height: 242px;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 36px;

    &_item {
      &__image {
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        img {
          -webkit-transform: translate3d(0, 0, 0);
        }

        &__pyth {
          filter: drop-shadow(2px 2px 20px #8246fa);
        }
      }

      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        text-align: center;
        font-size: 16px;
        line-height: 22px;
      }
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 24px;

      &__image {
        margin-bottom: 16px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  @media (max-width: 1024px) {
    &__inner {
      flex-direction: column;
    }

    &__info {
      min-width: auto;
      margin-bottom: 48px;

      h1 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }

    &__button {
      margin: 0 auto;
    }
  }

  @media (max-width: 767px) {
    &__inner {
      margin-bottom: 32px;
    }

    &__info {
      p {
        margin-bottom: 24px;

        br {
          display: none;
        }
      }
    }
  }
}
