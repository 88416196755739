@import "src/variables";

.stages {
  background: url("./../../assets/images/token_sale__bg.svg") no-repeat top center;
  background-size: cover;
  height: 900px;

  @media (max-width: 767px) {
    background: none;
    margin-top: 40px;
    height: fit-content;
  }

  &__stages_info {
    width: 100%;
    h2 {
      color: #fff;
      max-width: 1000px;
      text-align: center;
      margin: 0 auto 20px;
    }
    p {
      text-align: center;
      font-family: "Cabin";
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
    @media (max-width: 767px) {
      h2 {
        font-size: 20px;
        line-height: 24px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }

    &__header {
      display: flex;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 6px;
      margin: 32px auto 28px;
      justify-content: space-between;
      z-index: 1;
      max-width: 1160px;
      @media (max-width: 767px) {
        margin: 0;
        padding: 5px;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 16px;
        font-family: "Conthrax";
        font-weight: 600;
        line-height: 18px;
        text-transform: uppercase;
        padding: 13px 0px;
        color: $green;
        text-align: center;
        flex: 1;
        position: relative;
        @media (max-width: 767px) {
          font-size: 12px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          height: 100%;
          width: 100%;
          background-color: $green;
          z-index: -1;
          border-radius: 6px;
          opacity: 0;
          border: 0.5px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
          transition: all 0.25s ease-in-out;
          transform-origin: left;
        }

        &:global(.active) {
          color: #fff;

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      height: fit-content;
      max-width: 1225px;
      margin: 0 auto;
      background: rgba(33, 35, 41, 0.8);
      border-radius: 14px;
      backdrop-filter: blur(10px);
      position: relative;
      padding: 32px;
      border-radius: 10px;
      @media (max-width: 676px) {
        width: 100%;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
        border-radius: 10px;
        backdrop-filter: blur(10px);
      }
      h2 {
        color: #fff;
        position: relative;
        width: 100%;
        margin-bottom: 32px;

        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background: #d9d9d9;
          opacity: 0.2;
          left: 0;
          bottom: -20px;
        }
      }

      &__info {
        display: flex;
        flex-wrap: wrap;
        margin-top: 35px;
        justify-content: space-between;
        gap: 50px;

        @media (max-width: 676px) {
          flex-direction: column;
          &__info_block:nth-child(11) {
            &::after {
              display: block !important;
            }
          }
        }
        &__info_block:nth-child(5),
        &__info_block:nth-child(6),
        &__info_block:nth-child(7),
        &__info_block:nth-child(8) {
          &::after {
            display: none;
          }
        }
        @media (max-width: 1280px) {
          &__info_block:nth-child(5),
          &__info_block:nth-child(6) {
            &::after {
              display: block !important;
            }
          }
        }
        &__info_block {
          position: relative;
          width: 250px;
          margin-bottom: 22px;
          &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 100%;
            background: #d9d9d9;
            opacity: 0.2;
            left: 0;
            bottom: -23px;
          }
          p {
            font-weight: 600;
            text-transform: uppercase;
            font-family: "Conthrax";
            text-align: left;
          }
          &__title {
            font-size: 12px !important;
            line-height: 14px !important;
            color: #fff;
            margin-bottom: 8px !important;
          }
          &__value {
            font-size: 16px !important;
            line-height: 19px !important;
            color: $green;
          }
        }
      }
    }
  }
}

.presale {
  margin: 0;
  padding: 5px;
  margin-bottom: 20px;
  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background: #d9d9d9;
    opacity: 0.2;
    left: 0;
    bottom: -20px;
  }
  &__info {
    @media (max-width: 767px) {
      h2 {
        font-size: 22px;
        margin-bottom: 24px;
      }
      p:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
}
:global(.stage_heading) {
  &::before {
    display: none;
  }
}
