@import 'src/variables';

.button {
    padding: 0 16px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #374459;
    border-radius: 4px;
    font-size: 14px;
    line-height: 17px;
    color: $l3;
    text-transform: uppercase;
    gap: 8px;
    transition: 0.3s;
    &:hover {
        background: #374459;
    }
}