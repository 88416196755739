@import "src/variables";

.trading_products_section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 80px;

    @media (max-width: 767px) {
        padding-top: 0px;
        background: url("./../../assets/images/options1__bg_mobile.svg") no-repeat top -30px right;
        margin-top: 85px;
        background-size: 100%;
    }

    :global(.container) {
        margin: 0 auto;
    }

    >div {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    :global(.swiper-pagination-bullet) {
        border-radius: 30px;
        width: 28px;
        height: 2px;
        background-color: $green;
        opacity: 0.4;
    }

    :global(.swiper-pagination-bullet-active) {
        opacity: 1;
        width: 36px;
        height: 3px;
    }

    &__slider_buttons {
        position: absolute;
        bottom: 3px;
        height: fit-content;
        width: fit-content;
        cursor: pointer;
        z-index: 11;
    }

    &__slider_buttons_prev {
        left: 43%;

        svg {
            transform: rotate(180deg);
        }

        @media (max-width: 1024px) {
            left: 40%;
        }

        @media (max-width: 767px) {
            left: 25%;
        }
    }

    &__slider_buttons_next {
        right: 43%;

        @media (max-width: 1024px) {
            right: 40%;
        }

        @media (max-width: 767px) {
            right: 25%;
        }
    }

    .trading_products {
        padding: 40px 0px;
        background: url("./../../assets/images/options__bg.svg") no-repeat top -220px right;
        background-size: 100%;
        height: 100%;

        @media (max-width: 767px) {
            background: url("./../../assets/images/options__bg.svg") no-repeat top 400px center;
            background-size: 100%;
        }

        h1 {
            text-align: center;
            color: $green;
            margin-bottom: 48px;
            font-size: 34px;

            @media (max-width: 767px) {
                font-size: 24px;
                line-height: 34px;
                margin-bottom: 30px;
                width: calc(100% + 32px);
                left: -16px;
            }
        }

        &__block {
            display: flex;
            justify-content: space-between;
            gap: 36px;

            @media (max-width: 1024px) {
                flex-direction: column;
                align-items: flex-end;
            }

            @media (max-width: 767px) {
                align-items: flex-start;
                gap: 12px;
            }

            &_info {
                width: 499px;
                z-index: 1;

                @media (max-width: 1280px) {
                    min-width: auto !important;
                }

                @media (max-width: 1024px) {
                    width: 100%;
                }

                p {
                    width: 100%;
                }
            }

            &_column {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-left: -200px;

                @media (max-width: 767px) {
                    width: 100%;
                    align-items: flex-start;
                    margin-left: 0;
                }
            }

            &_advantages {
                display: grid;
                grid-template-columns: 286px 286px;
                row-gap: 15px;
                column-gap: 30px;
                z-index: 2;

                @media (max-width: 1280px) {
                    column-gap: 15px;
                }

                @media (max-width: 767px) {
                    grid-template-columns: 177px;
                    grid-template-rows: repeat(4, 46px);
                }

                div {
                    background: rgba(16, 19, 29, 0.5);
                    backdrop-filter: blur(8px);
                }
            }

            &_image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 785px;
                height: 430px;
                margin-top: -140px;

                @media (max-width: 767px) {
                    width: 330px;
                    height: 215px;
                    position: absolute;
                    right: -13px;
                    bottom: -48px;
                    z-index: 1;
                }

                picture {
                    position: absolute;
                    width: 900px;

                    @media (max-width: 767px) {
                        width: 467px;
                        left: -89px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    .binary {
        // padding-top: 132px;
        // padding-bottom: 100px;
        background: url("./../../assets/images/binary__bg.svg") no-repeat center center;
        background-size: 100%;
        padding: 40px 0px;

        @media (max-width: 767px) {
            padding-top: 64px;
            padding-bottom: 36px;
            background: url("./../../assets/images/binary__bg_mobile.svg") no-repeat bottom 60px center;
            background-size: 100%;
        }

        :global(.container) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 1280px) {
                flex-direction: column-reverse;
            }
        }

        &__info {
            left: -32px;

            @media (max-width: 1280px) {
                min-width: auto !important;
                width: 100%;
                left: 0;

                p {
                    width: 100%;
                }

                h3 {
                    width: 100%;
                }
            }

            h3 {
                width: 445px;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }
        }

        &__image {
            display: flex;
            align-items: center;

            &_container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 424px;
                height: 568px;
                z-index: 1;

                @media (max-width: 767px) {
                    width: 169px;
                    height: 265px;
                }

                picture {
                    position: absolute;
                    left: -110px;
                    width: 1280px;

                    @media (max-width: 767px) {
                        width: 510px;
                        left: -44px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            &_advantages {
                margin-left: -24px;
                display: grid;
                grid-template-columns: 230px;
                gap: 16px;
                top: -20px;

                @media (max-width: 767px) {
                    grid-template-columns: 183px;
                    margin-left: -12px;
                    top: 0;
                }
            }
        }
    }

    .touch {
        padding: 60px 0px;
        background: url("./../../assets/images/touch__bg.svg") no-repeat center -70px;
        background-size: cover;
        z-index: 1;

        @media (max-width: 767px) {
            padding-bottom: 36px;
            background: url("./../../assets/images/touch__bg_mobile.svg") no-repeat bottom -355px center;
            background-size: 100%;
        }

        h3 {
            font-size: 30px;
            line-height: 40px;
            text-align: center;
            letter-spacing: 0.04em;
            color: $l6;
            text-transform: uppercase;
            margin-bottom: 36px;

            @media (max-width: 767px) {
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.04em;
                text-align: left;
                margin-bottom: 24px;
            }
        }

        &__advantages {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 15px;
            margin-bottom: 44px;
            z-index: 2;

            @media (max-width: 1024px) {
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: 767px) {
                grid-template-columns: 177px;
                grid-template-rows: repeat(4, 46px);
                margin-bottom: 46px;
                z-index: 1;

                div:not(:first-child) {
                    strong {
                        width: 130px;
                    }
                }
            }

            div {
                background: rgba(16, 19, 29, 0.5);
                backdrop-filter: blur(8px);
            }
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media (max-width: 1024px) {
                flex-direction: column-reverse;
                gap: 24px;
            }

            &_block {
                width: 547px;

                @media (max-width: 1280px) {
                    min-width: auto !important;
                    width: 40%;
                }

                @media (max-width: 1024px) {
                    width: 100%;
                }

                p {
                    width: 100%;
                    z-index: 1;
                }
            }

            &_image {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 627px;
                height: 424px;

                @media (max-width: 767px) {
                    position: absolute;
                    top: 332px;
                    right: -28px;
                    width: 272px;
                    height: 184px;
                }

                picture {
                    position: absolute;
                    // left: -485px;
                    top: -120px;
                    width: 1229px;

                    @media (max-width: 767px) {
                        width: 662px;
                        left: -210px;
                        top: -63px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}