$green: #00cd86;
$grey: #59648a;
$l1: #374459;
$l2: #0d1422;
$l3: #51ad5f;
$l4: #175610;
$l5: #b5b8c5;
$l6: #cdd6f5;
$l7: #222237;
$l8: #222a3f;
