@import 'src/variables';

.advantage {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(205, 214, 245, 0.3);
  border-radius: 4px;
  @media (max-width: 767px) {
    padding: 8px;
  }
  img {
    width: 24px;
    height: 24px;
    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }
  strong {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: $green;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 10px;
      line-height: 12px;
    }
  }
}