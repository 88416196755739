.download {
  display: none;
  @media (max-width: 768px) {
    position: absolute;
    top: 60px;
    left: 0;
    display: block;
    width: 100%;
    background: linear-gradient(85.95deg, #23a6c3 11.48%, #0c5e9a 85.91%);
    padding: 8px 16px;
    z-index: 1;
    p {
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      img {
        transform: rotate(-90deg);
        height: 8px;
        top: 1px;
        left: -2px;
      }
    }
  }
}
