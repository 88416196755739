@import "src/variables";

.transactions {
  padding-top: 70px;
  padding-bottom: 64px;
  // background: url("./../../assets/images/transactions__bg.svg") no-repeat center
  //   center;
  // background-size: 100%;
  @media (max-width: 767px) {
    padding-top: 48px;
    padding-bottom: 0;
  }
  :global(.container) {
    :global(.features-wrapper) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media (max-width: 1200px) {
        grid-template-columns: repeat(1, 1fr);
        column-gap: 0px;
      }
      column-gap: 32px;
      row-gap: 30px;
      margin-bottom: 91px;
      :global(.feature) {
        background: linear-gradient(147.75deg, #ffffff1a 0%, #ffffff0d 100%);
        box-shadow: 0px 0px 24px #00000040, inset 0px 0px 30px #10121880;
        backdrop-filter: blur(10px);
        border-radius: 15px;
        padding: 16px 20px 24px 20px;
        min-height: 190px;
        @media (max-width: 1200px) {
          width: 600px;
          display: block;
          margin: 0px auto;
          min-height: unset;
        }
        @media (max-width: 767px) {
          width: 100%;
          min-height: unset;
        }
        :global(.header) {
          display: flex;
          column-gap: 18px;
          align-items: center;
          margin-bottom: 12px;
          @media (max-width: 767px) {
            flex-direction: column;
            column-gap: 0px;
            row-gap: 0px;
            align-items: flex-start;
            margin-bottom: 8px;
          }
          :global(.icon) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 60px;
            border-radius: 30px;

            @media (max-width: 767px) {
              width: 54px;
              height: 54px;
              margin-bottom: 16px;
              img {
                width: 33px;
              }
            }
          }
          strong {
            color: $l6;
            max-width: 450px;
          }
        }
        p {
          font-size: 16px;
          line-height: 22px;
          line-break: auto;
          font-weight: 400;
        }
        :global(.secure) {
          max-width: 370px;
        }
      }
    }
  }
  h2 {
    text-align: center;
    color: $green;
    margin-bottom: 34px;
    font-size: 34px;
    @media (max-width: 767px) {
      width: 80%;
      margin: 0 auto;
    }
  }
  > p {
    max-width: 980px;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 767px) {
      br {
        display: none;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 48px;
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
    }
  }
  @media (max-width: 767px) {
    h2 {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }
  }

  // &__slider {
  //   margin-bottom: 48px;
  //   overflow: visible !important;
  //   z-index: 3 !important;
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     right: calc((100vw - 1228px) / -2 - 1px);
  //     width: 270px;
  //     height: 100%;
  //     background: linear-gradient(90deg, rgba(16, 18, 24, 0), #101218);
  //     opacity: 0.9;
  //     z-index: 1;
  //     pointer-events: none;
  //     @media (max-width: 1280px) {
  //       right: -24px;
  //     }
  //     @media (max-width: 767px) {
  //       display: none;
  //     }
  //   }
  //   &:before {
  //     content: "";
  //     position: absolute;
  //     top: 0;
  //     left: calc((100vw - 1228px) / -2 - 1px);
  //     width: 270px;
  //     height: 100%;
  //     background: linear-gradient(270deg, rgba(16, 18, 24, 0), #101218);
  //     opacity: 0.9;
  //     z-index: 2;
  //     pointer-events: none;
  //     @media (max-width: 1280px) {
  //       left: -24px;
  //     }
  //     @media (max-width: 767px) {
  //       display: none;
  //     }
  //   }
  //   :global(.swiper-slide) {
  //     backdrop-filter: blur(16px);
  //     border-radius: 4px;
  //     padding: 24px;
  //     overflow: hidden;
  //     height: auto;
  //     :global(.background) {
  //       display: flex;
  //       justify-content: flex-end;
  //       position: absolute;
  //       width: 100%;
  //       height: 100%;
  //       top: 0;
  //       left: 0;
  //       img {
  //         height: 100%;
  //       }
  //       pointer-events: none;
  //     }

  //     strong {
  //       display: block;
  //       color: #ffffff;
  //       margin-bottom: 16px;
  //       font-size: 16px;
  //       line-height: 19px;
  //       letter-spacing: 0.04em;
  //       text-transform: uppercase;
  //       @media (max-width: 767px) {
  //         margin-bottom: 8px;
  //       }
  //     }
  //     p {
  //       font-size: 16px;
  //       line-height: 22px;
  //       color: #ffffff;
  //       @media (max-width: 767px) {
  //         font-size: 14px;
  //         line-height: 20px;
  //         font-weight: 300;
  //       }
  //     }
  //   }
  //   :global(.swiper-pagination) {
  //     display: none;
  //     @media (max-width: 767px) {
  //       display: block;
  //     }
  //     bottom: -32px !important;
  //     width: 100vw !important;
  //     left: -16px !important;
  //     :global(.swiper-pagination-bullet) {
  //       width: 10px;
  //       height: 10px;
  //       border: 1px solid #59648a;
  //       background: none;
  //       opacity: 1;
  //       margin: 0 2px !important;
  //     }
  //     :global(.swiper-pagination-bullet-active) {
  //       background: #cdd6f5;
  //       border: 2px solid #59648a;
  //     }
  //   }
  //   @media (max-width: 767px) {
  //     width: calc(100vw - 44px);
  //     margin-left: 0 !important;
  //   }
  // }
  &__image {
    img {
      margin-bottom: -80px;
      @media (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
  }
  &__why {
    h3 {
      text-align: center;
      color: $l6;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
      }
    }
    &_items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, 140px);
      gap: 1px;
      background: #222237;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #101218;
        text-align: center;
        padding: 24px;
        strong {
          display: block;
          color: $grey;
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 19px;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          color: $l6;
          img {
            width: 16px;
            margin-right: 4px;
            top: 2px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      @media (max-width: 950px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4, 150px);
        div {
          padding: 0;
          &:nth-child(odd) {
            padding-right: 16px;
          }
          &:nth-child(even) {
            padding-left: 16px;
          }
          strong {
            font-size: 14px;
            line-height: 17px;
          }
          p {
            font-size: 14px;
            line-height: 20px;
            br {
              display: none;
            }
          }
        }
      }
    }
  }
}
