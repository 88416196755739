@import "src/variables";

.staking {
  padding-top: 192px;
  padding-bottom: 124px;
  background: url("./../../assets/images/staking__bg.svg") no-repeat top center;
  background-size: 100%;
  @media (min-width: 1280px) and (max-width: 1600px) {
    padding-top: 162px;
  }
  @media (max-width: 767px) {
    padding-bottom: 138px;
    padding-top: 148px;
    background: url("./../../assets/images/staking__bg_mobile.svg") no-repeat top center;
    background-size: 100%;
  }
  h2 {
    text-align: center;
    color: $green;
    margin-bottom: 32px;
    @media (min-width: 1280px) and (max-width: 1600px) {
      margin-bottom: 22px;
    }
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }
  }
  p {
    margin: 0 auto 24px;
    max-width: 808px;
    width: 100%;
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    font-family: "Conthrax";
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  button {
    margin: 0 auto;
    @media (max-width: 546px) {
      width: 100%;
    }
    &:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
  &__block {
    position: static;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
    }
    &_info {
      position: static;
      h2 {
        text-align: center;
      }
      @media (max-width: 1280px) {
        min-width: auto !important;
        width: 40%;
      }
      @media (max-width: 1024px) {
        width: 100%;
        margin-bottom: 24px;
      }
      @media (max-width: 767px) {
        margin-bottom: 0;
        h2 {
          text-align: center;
        }
      }
      p {
        min-width: 100%;
        @media (max-width: 1280px) {
          min-width: auto !important;
          width: 100%;
        }
      }
      strong {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #cdd6f5;
        margin-bottom: 24px;
        span {
          color: #00cd86;
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 286px;
        height: 54px;
        background: $l3;
        border-radius: 4px;
        font-size: 14px;
        line-height: 17px;
        color: $l4;
        text-transform: uppercase;
        @media (max-width: 1024px) {
          width: calc(100% - 32px);
          position: absolute;
          bottom: -70px;
          left: 16px;
        }
      }
    }
    &_image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 627px;
      height: 424px;
      @media (max-width: 767px) {
        width: 396px;
        height: 268px;
      }
      picture {
        position: absolute;
        left: -435px;
        top: -90px;
        width: 1529px;
        @media (max-width: 767px) {
          width: 963px;
          left: -280px;
          top: -54px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  &__apy {
    margin-top: 60px;
    margin-bottom: 60px;
    @media (min-width: 1280px) and (max-width: 1600px) {
      margin-bottom: 32px;
      margin-top: 32px;
    }
    h3 {
      font-size: 22px;
      line-height: 26px;
      color: $l6;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
    &_items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      border-radius: 4px 4px 0 0;
      column-gap: 30px;
      @media (max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
      }
      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }
  }
}

:global(.level-1) {
  &::before {
    background: #1aeefc;
  }
  &::after {
    background: #1aeefc;
  }
}
:global(.level-2) {
  &::before {
    background: #31a1f2;
  }
  &::after {
    background: #31a1f2;
  }
}
:global(.level-3) {
  &::before {
    background: #e7da65;
  }
  &::after {
    background: #e7da65;
  }
}
:global(.level-4) {
  &::before {
    background: #f65555;
  }
  &::after {
    background: #f65555;
  }
}

.apy {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 14px;
  backdrop-filter: blur(15px);
  width: 280px;
  height: 316px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.04;
    border-radius: 14px;
    box-shadow: 0px 0px 27px #1aeefc;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  &::after {
    content: "";
    position: absolute;
    height: 6px;
    width: 100%;
    border-radius: 0px 0px 14px 14px;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media (max-width: 1280px) {
    margin: 0 auto;
  }
  p {
    font-family: "Cabin";
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: $l6;
    text-transform: uppercase;
  }
  &:not(:last-child) {
    // border-right: 1px solid rgba(205, 214, 245, 0.3);
  }
  @media (max-width: 1280px) {
    // &:not(:last-child) {
    //   border-right: 0;
    // }
    // &:not(:nth-last-of-type(-n + 2)) {
    //   border-bottom: 1px solid rgba(205, 214, 245, 0.3);
    // }
    // &:nth-child(odd) {
    //   border-right: 1px solid rgba(205, 214, 245, 0.3);
    // }
  }
  @media (max-width: 767px) {
    padding: 16px;
    border-bottom: 1px solid rgba(205, 214, 245, 0.3);

    &:last-child {
      border-bottom: 0;
    }
    &:nth-child(odd) {
      border-right: 0;
    }
  }
  @media (max-width: 546px) {
    width: 100%;
  }
  &__icons {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 46px;
      img {
        position: absolute;
        height: 46px;
      }
    }
    span {
      font-family: Conthrax;
      font-size: 16px;
      line-height: 19px;
      margin: 0 16px;
      color: $grey;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 17px;
    color: $l6;
    margin-bottom: 8px;
    text-align: center;
  }
  &__stats {
    margin: 0 auto;
    @media (max-width: 546px) {
      margin: 0 auto 15px;
    }

    div {
      display: flex;
      align-items: center;
      background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
      backdrop-filter: blur(10px);
      border-radius: 15px;
      padding: 8px 24px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      strong {
        font-size: 22px;
        line-height: 26px;
        color: $l6;
      }
    }
  }
}
