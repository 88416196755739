@import "src/variables";

.timer {
  display: flex;
  > strong {
    font-size: 30px;
    line-height: 48px;
    color: $green;
    margin: 0 16px;
    opacity: 0.4;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 34px;
      margin: 0 10px;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    strong {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 48px;
      border: 1px solid #374459;
      border-radius: 6px;
      margin-bottom: 4px;
      font-size: 30px;
      line-height: 40px;
      color: $green;
      @media (max-width: 767px) {
        width: 45px;
        height: 45px;
        font-size: 20px;

        line-height: 24px;
        border-radius: 4px;
      }
    }
  }
}
