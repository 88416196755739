@import "src/variables";

.sale {
  padding-top: 400px;
  display: flex;
  flex-direction: column;
  background: url("./../../assets/images/sale__bg.svg") no-repeat top center;
  background-size: 100%;
  z-index: 1;
  @media (max-width: 1280px) {
    padding-top: 300px;
  }
  @media (max-width: 1024px) {
    padding-top: 156px;
  }
  @media (max-width: 546px) {
    padding-top: 156px;
    background: url("./../../assets/images/sale__bg_mobile.svg") no-repeat top -84px center;
    background-size: 100%;
  }
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__slider {
    height: 560px;
    margin-bottom: 50px;
    overflow: visible;
    &::before {
      content: "";
      width: 100%;
      height: 60px;
      background: linear-gradient(0deg, rgba(16, 18, 24, 0) 0%, #101218 100%);
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 2;
    }
    &::after {
      content: "";
      width: 100%;
      height: 60px;
      background: linear-gradient(180deg, rgba(16, 18, 24, 0) 0%, #101218 100%);
      position: absolute;
      bottom: 0;
      left: 0;
      pointer-events: none;
      z-index: 2;
    }
    :global(.swiper-slide) {
      height: auto;
      > div {
        height: 100%;
        padding: 30px 0;
        :global(.container) {
          padding-top: 0;
        }
      }
      h3 {
        height: 48px;
      }
    }
    :global(.swiper-pagination) {
      bottom: -20px;
      :global(.swiper-pagination-bullet) {
        width: 10px;
        height: 10px;
        border: 1px solid #59648a;
        background: none;
        opacity: 1;
        margin: 0 2px !important;
      }
      :global(.swiper-pagination-bullet-active) {
        background: #cdd6f5;
        border: 2px solid #59648a;
      }
    }
  }
}

.token_sale {
  padding-bottom: 124px;
  margin-top: 50px;
  @media (max-width: 767px) {
    padding-bottom: 40px;
  }

  h2 {
    font-size: 34px;
    line-height: 46px;
    color: $green;
    text-align: center;
    margin-bottom: 16px;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
    }
  }
  p {
    text-align: center;
    margin-bottom: 16px;
    a {
      color: $green;
    }
  }
  button {
    margin: 0 auto 48px;
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }

  &__wrapper {
    display: flex;
    gap: 30px;

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__item_one {
    @media (min-width: 1200px) {
      width: 580px;
    }
    h2 {
      text-align: left;
      @media (max-width: 546px) {
        margin-left: auto;
        margin-right: auto;
        width: 220px;
      }
    }
    p {
      text-align: left;
      max-width: 420px;
      @media (max-width: 546px) {
        text-align: center;
      }
    }
    a {
      button {
        margin: 0 0 48px 0;
        @media (max-width: 546px) {
          width: 100%;
        }
      }
    }
  }
  &__item_two {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 610px;
    background: rgba(16, 18, 24, 0.4);
    border-radius: 14px;
    box-shadow: 0px 0px 70px 1px rgba(1, 205, 134, 0.13);
    backdrop-filter: blur(10px);
    position: relative;
    padding: 32px;
    border-radius: 10px;
    @media (max-width: 676px) {
      width: 100%;
      padding: 24px;
      background: rgba(33, 35, 41, 0.8);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(147.75deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
      border-radius: 10px;
      backdrop-filter: blur(10px);
    }
    h2 {
      color: #fff;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        height: 1px;
        width: 100%;
        background: #d9d9d9;
        opacity: 0.2;
        left: 0;
        bottom: -24px;
      }
    }
    &__info {
      display: flex;
      flex-wrap: wrap;
      margin-top: 35px;
      justify-content: space-between;
      @media (max-width: 676px) {
        flex-direction: column;
      }

      &__header {
        width: 100%;
        margin-top: 35px;
        padding-bottom: 22px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background: #d9d9d9;
          opacity: 0.2;
          left: 0;
          bottom: -4px;
        }
        p,
        a {
          font-weight: 600;
          font-family: "Conthrax";
          text-align: left;
        }
        &__title {
          font-size: 12px;
          line-height: 14px;
          text-transform: uppercase;
          color: #fff;
          margin-bottom: 8px !important;
        }
        &__value {
          font-size: 16px;
          line-height: 19px;
          color: $green;
          word-break: break-all;
          cursor: pointer;
        }
      }

      &__info_block {
        position: relative;
        width: 230px;
        margin-bottom: 22px;
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background: #d9d9d9;
          opacity: 0.2;
          left: 0;
          bottom: -4px;
        }
        p {
          font-weight: 600;
          text-transform: uppercase;
          font-family: "Conthrax";
          text-align: left;
        }
        &__title {
          font-size: 12px;
          line-height: 14px;
          color: #fff;
          margin-bottom: 8px !important;
        }
        &__value {
          font-size: 16px;
          line-height: 19px;
          color: $green;
        }
        &:last-child {
          margin-bottom: 0;
          p:last-child {
            margin-bottom: 0;
          }
          &::after {
            display: none;
          }
        }
        @media (min-width: 767px) {
          &:nth-last-child(2) {
            margin-bottom: 0;
            p:last-child {
              margin-bottom: 0;
            }
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
  &__counters {
    display: flex;
    justify-content: center;
    gap: 30px;
    @media (max-width: 767px) {
      background: url("./../../assets/images/rectangle.svg") no-repeat;
      background-size: 100%;
      // width: 320px;
      height: 200px;
      // flex-direction: column;
      // align-items: center;
      margin: 0 auto;
    }
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 600px;
      height: 323px;
      background: url("./../../assets/images/rectangle.svg") no-repeat;
      background-size: 100%;
      // @media (max-width: 1280px) {
      //   width: 6005px;
      //   height: 375px;
      // }
      @media (max-width: 767px) {
        width: auto;
        height: auto;
        background: none;
      }
      &__title {
        font-size: 16px;
        line-height: 23px;
        color: $l6;
        margin-bottom: 16px;
        text-transform: uppercase;
        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 12px;
          margin-bottom: 8px;
        }
      }
      &__price {
        font-size: 30px;
        line-height: 40px;
        color: $green;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      span {
        font-size: 14px;
        line-height: 20px;
        color: $grey;
      }
    }
  }
}
.presale {
  max-width: 1225px;
  margin-top: 32px;
  margin-bottom: 94px;
  width: 100%;
  background: rgba(33, 35, 41, 0.8);
  border-radius: 14px;
  box-shadow: 0px 0px 70px 1px rgba(1, 205, 134, 0.13);
  backdrop-filter: blur(10px);
  position: relative;
  padding: 32px;
  border-radius: 10px;
  h2 {
    text-align: center;
  }
  @media (max-width: 767px) {
    h2 {
      font-size: 24px;
      line-height: 34px;
    }
  }

  &__info {
    gap: 50px;
    margin-top: 52px;
    &__info_block {
      &::after {
        bottom: -26px;
      }

      &:last-child {
        margin-bottom: 0;
        p:last-child {
          margin-bottom: 0;
        }
        &::after {
          display: none;
        }
      }

      &:nth-last-child(2),
      &:nth-last-child(3),
      &:nth-last-child(3),
      &:nth-last-child(4) {
        margin-bottom: 0;

        &::after {
          display: none;
        }
      }
      @media (max-width: 1140px) {
        &:nth-last-child(2) {
          margin-bottom: 0;

          &::after {
            display: none;
          }
        }
        &:nth-last-child(3),
        &:nth-last-child(4) {
          margin-bottom: 22px;
          &::after {
            display: block;
          }
        }
      }
      @media (max-width: 676px) {
        &:nth-last-child(2) {
          margin-bottom: 22px;
          &::after {
            display: block;
          }
        }
      }
    }
  }
}

.timer {
  display: flex;
  > strong {
    font-size: 30px;
    line-height: 48px;
    color: $green;
    margin: 0 16px;
    opacity: 0.4;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 34px;
      margin: 0 10px;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    strong {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 48px;
      border: 1px solid #374459;
      border-radius: 6px;
      margin-bottom: 4px;
      font-size: 30px;
      line-height: 40px;
      color: $green;
      @media (max-width: 767px) {
        width: 40px;
        height: 34px;
        font-size: 20px;
        line-height: 24px;
        border-radius: 4px;
      }
    }
  }
}

.blx {
  padding-bottom: 117px;
  background: url("./../../assets/images/blx__bg.svg") #101218 no-repeat center top;
  background-size: 100%;
  @media (max-width: 767px) {
    background: url("./../../assets/images/blx__bg_mobile.svg") #101218 no-repeat center center;
    background-size: cover;
  }
  :global(.container) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1280px) {
      flex-direction: column-reverse;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 605px;
    @media (max-width: 767px) {
      display: none;
    }
    img {
      position: absolute;
    }
  }
  &__info {
    width: 515px;
    @media (max-width: 1280px) {
      width: 100%;
    }
    p {
      min-width: 100%;
    }
  }
}

.dao {
  margin-bottom: 88px;
  @media (max-width: 767px) {
    background: url("./../../assets/images/dao__bg_mobile.svg") no-repeat center center;
    background-size: cover;
  }
  :global(.container) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1280px) {
      flex-direction: column;
    }
  }
  &__info {
    width: 496px;
    @media (max-width: 1280px) {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
  &__animation {
    width: 600px;
  }
}

.buy_blx {
  margin-bottom: 116px;
  background: url("./../../assets/images/buy_blx__bg.svg") no-repeat center center;
  background-size: cover;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 280px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), #101218);
  }
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 280px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0), #101218);
  }
  picture {
    width: 629px;
    img {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    background: url("./../../assets/images/buy_blx__bg_mobile.svg") no-repeat center center;
    background-size: cover;
    img {
      display: none;
    }
  }
  :global(.container) {
    padding-top: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1280px) {
      flex-direction: column-reverse;
    }
  }
  &__info {
    @media (max-width: 1280px) {
      width: 100%;
      p {
        width: 100%;
      }
    }
    h3 {
      width: 391px;
      @media (max-width: 1280px) {
        width: 100%;
      }
    }
  }
}

.graphs {
  @media (max-width: 767px) {
    margin-bottom: -3px;
  }
  :global(.container) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > p {
      max-width: 975px;
      margin-bottom: 36px;
      @media (max-width: 767px) {
        margin-bottom: 24px;
      }
      span {
        color: $green;
      }
    }
  }
  &__tokens {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 64px;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
    img {
      margin-bottom: 16px;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
  }
  &__label {
    font-size: 16px;
    line-height: 22px;
    color: $grey;
  }
  &__formula {
    font-style: italic;
    font-size: 26px;
    line-height: 29px;
    color: $green;
    margin-bottom: 36px;
    margin-top: -18px;
    @media (max-width: 767px) {
      margin-bottom: 16px;
      margin-top: -8px;
    }
    &_grade {
      right: -16px;
      top: -8px;
      position: absolute;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 1;
      color: $green;
      span:first-child {
        margin-bottom: 6px;
        &:after {
          position: absolute;
          left: 1px;
          bottom: -3px;
          content: "";
          width: 8px;
          height: 1px;
          background: $green;
        }
      }
    }
  }
  &__pies {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 136px;
    margin-bottom: 90px;
    @media (max-width: 1280px) {
      flex-direction: column;
      gap: 60px;
    }
    @media (max-width: 767px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 767px) {
        &:last-child {
          div {
            img {
              width: calc(100% + 14px);
              max-width: none;
            }
          }
        }
      }
      div {
        width: 550px;
        height: 550px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 24px;
        @media (max-width: 767px) {
          width: 100%;
          height: auto;
        }
        picture {
          position: absolute;
          left: -24px;
          @media (max-width: 767px) {
            position: static;
            left: 0;
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &__bars {
    margin-bottom: 130px;
    @media (max-width: 767px) {
      margin-bottom: 60px;
    }
    img {
      margin-bottom: 24px;
      @media (max-width: 1280px) {
        width: 100%;
      }
    }
  }
}

.uniswap {
  padding-bottom: 270px;
  background: url("./../../assets/images/uniswap__bg.svg") no-repeat center center;
  background-size: 100%;
  margin-bottom: -64px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 280px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0), #101218);
  }
  @media (max-width: 767px) {
    padding-bottom: 64px;
    background: url("./../../assets/images/uniswap__bg_mobile.svg") no-repeat bottom -80px center;
    background-size: 100%;
  }
  :global(.container) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  &__info {
    @media (max-width: 1024px) {
      min-width: 100%;
      p {
        width: 100%;
      }
    }
  }
  &__circles {
    display: flex;
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 300px;
      height: 300px;
      border: 1px solid rgba(205, 214, 245, 0.3);
      border-radius: 150px;
      @media (max-width: 767px) {
        width: 188px;
        height: 188px;
      }
      &:nth-child(1) {
        strong {
          color: $green;
        }
        margin-right: -50px;
        @media (max-width: 767px) {
          margin-right: -30px;
        }
      }
      &:nth-child(2) {
        strong {
          color: #ff007a;
        }
      }
      img {
        margin-bottom: 24px;
        @media (max-width: 767px) {
          height: 52px;
        }
      }
      strong {
        text-transform: uppercase;
        font-size: 20px;
        line-height: 23px;
        @media (max-width: 767px) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
}
