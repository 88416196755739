@import "src/variables";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -80px;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
  :global(.container) {
    padding: 24px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 1280px) {
      padding: 24px;
    }
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    gap: 32px;
    @media (max-width: 1024px) {
      order: 2;
      margin-bottom: 36px;
    }
    li {
      display: flex;
      a {
        display: flex;
        font-size: 16px;
        line-height: 22px;
        color: $l6;
        transition: 0.3s;
        &:hover {
          color: #00cd86;
        }
      }
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
      order: 1;
      margin-bottom: 24px;
    }
    img {
      width: 198px;
    }
  }
  &__media {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    @media (max-width: 1024px) {
      order: 3;
    }
    li {
      display: flex;
      a {
        display: flex;
        svg {
          transition: 0.3s;
          path {
            transition: 0.3s;
          }
          rect {
            transition: 0.3s;
          }
        }
        &:hover {
          svg {
            > path {
              fill: #00cd86;
            }
            // for mail icon
            rect {
              fill: #00cd86;
            }
          }
        }
      }
    }
  }
  &__copyright {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 100%;
    color: $grey;
    padding: 16px 0;
    border-top: 1px solid rgba(205, 214, 245, 0.1);
  }
}
