@import 'src/variables';

.info_block {
  display: flex;
  flex-direction: column;
  min-width: 496px;
  @media (max-width: 767px) {
    min-width: auto;
  }
  h2 {
    font-size: 34px;
    line-height: 46px;
    letter-spacing: 0.04em;
    color: $green;
    margin-bottom: 64px;
    width: 100%;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #CDD6F5;
      opacity: 0.3;
      position: absolute;
      bottom: -32px;
      left: 0;
    }
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
      &:after {
        display: none;
      }
    }
  }
  h3 {
    font-size: 30px;
    line-height: 40px;
    color: $l6;
    margin-bottom: 64px;
    width: 100%;
    text-transform: uppercase;
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: #CDD6F5;
      opacity: 0.3;
      position: absolute;
      bottom: -32px;
      left: 0;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 16px;
      &:after {
        display: none;
      }
    }
  }
  p {
    width: 473px;
    margin-bottom: 30px;
    a {
      color: $green;
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  > img {
    width: 100%;
    margin-bottom: 24px;
  }
  button {
    @media (max-width: 767px) {
      width: 100%;
    }
  }
}