@import 'src/variables';

.popup_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #020610;
    opacity: 0.8;
  }
}

.popup {
  max-width: 675px;
  width: 675px;
  background: #181D2D;
  border-radius: 6px;
  @media (max-width: 768px) {
    margin: 0 16px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 24px;
    border-bottom: 1px solid #374459;
    strong {
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      text-transform: uppercase;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 24px 48px;
    img {
      margin-bottom: 24px;
    }
    strong {
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 4px;
      color: $green;
      text-transform: uppercase;
      text-align: center;
    }
    p {
      font-size: 14px;
      line-height: 140%;
      color: #FFFFFF;
      font-weight: bold;
      text-align: center;
      margin-bottom: 36px;
      a {
        color: $green;
      }
    }
    button {
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}