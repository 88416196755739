@import 'src/variables';

.faq {
  padding-top: 100px;
  padding-bottom: 280px;
  background: url('./../../assets/images/faq__bg.svg') no-repeat center center;
  background-size: 100% auto;
  @media (max-width: 767px) {
    padding-top: 60px;
    padding-bottom: 64px;
    background: url('./../../assets/images/faq__bg_mobile.svg') no-repeat top center;
    background-size: 100%;
  }
  h2 {
    font-size: 34px;
    line-height: 46px;
    color: $green;
    margin-bottom: 24px;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 16px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 810px;
    margin: 0 auto;
    &_item {
      padding: 20px 16px 16px;
      border: 1px solid #374459;
      border-radius: 10px;
      &:after {
        position: absolute;
        right: 16px;
        top: 20px;
        content: '';
        width: 16px;
        height: 16px;
        background: url('./../../assets/images/arrow_white.svg') no-repeat center center;
      }
      strong {
        font-size: 14px;
        line-height: 17px;
        color: $l6;
        text-transform: uppercase;
        padding-right: 24px;
      }
      p {
        display: none;
        font-size: 16px;
        line-height: 22px;
        margin-top: 16px;
      }
      &_active {
        strong {
          color: $green;
        }
        p {
          display: inline-block;
        }
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
}