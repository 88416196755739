.sorting {
  &__selected {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    &_active {
      img {
        transform: rotate(180deg);
      }
    }
    strong {
      top: 2px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 1;
    background: #101218;
    padding: 12px;
    gap: 8px;
    border-radius: 4px;
    width: auto;
    white-space: nowrap;
  }
  strong {
    font-size: 10px;
    letter-spacing: 0.04em;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
  }
}