.language {
  height: 100%;
  margin-right: 16px;
  img {
    width: 24px;
  }
  &__current {
    cursor: pointer;
    height: 100%;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d1422;
    border: 1px solid #374459;
    border-radius: 4px;
    &::after {
      position: relative;
      content: "";
      border: 4px solid transparent;
      border-top: 4px solid #ffffff;
      top: 2px;
      margin: 0 4px;
    }
  }
  &__dropdown {
    position: absolute;
    top: calc(100% + 8px);
    left: -1px;
    padding: 4px 0;
    width: 48px;
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #0d1422;
    border: 1px solid #374459;
    border-radius: 4px;
  }
}
